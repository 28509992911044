

import { inject } from '@/inversify';
import { Component, Vue } from 'vue-property-decorator';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import CarsAnalysisFiltersService, { CarsAnalysisFiltersServiceS } from '../cars-analysis-filters.service';

@Component({
    components: {
        CustomSelect,
    },
})
export default class CarRatesAnalysisComparison extends Vue {
    @inject(CarsAnalysisFiltersServiceS) carsAnalysisFiltersService!: CarsAnalysisFiltersService;

    get comparisonType() {
        return this.carsAnalysisFiltersService.comparisonKey;
    }

    set comparisonType(value: string) {
        if (value !== this.comparisonType) {
            this.carsAnalysisFiltersService.comparisonKey = value;
        }
    }

    get comparisonValue() {
        return this.carsAnalysisFiltersService.comparisonValues.value;
    }

    set comparisonValue(value: string | number) {
        const findItem = this.comparisonValuesItems.find(element => element.value === value);
        const defaultValue: Item = this.comparisonValuesItems[0];
        this.carsAnalysisFiltersService.comparisonValues = findItem || defaultValue;
    }

    get comparisonTypeItems(): Item[] {
        return this.carsAnalysisFiltersService.filterList;
    }

    get comparisonValuesItems(): Item[] {
        const { currentFilterItems } = this.carsAnalysisFiltersService;
        return currentFilterItems;
    }

    comparisonValueTitle(selectedValue: number | string) {
        const findElement = this.comparisonValuesItems.find(element => element.value === selectedValue);
        return findElement ? findElement.name : '';
    }

    comparisonTypeTitle(selectedType: string) {
        const findElement = this.comparisonTypeItems.find(element => element.value === selectedType);
        return findElement ? findElement.name : 'diffDays';
    }
}
